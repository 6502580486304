import logo from '@/assets/via-logo-black.png';
import { TextButton } from '@/components/Elements/TextButton';
import { logout } from '@/features/auth';
import { windowReplaceOriginUrl } from '@/utils/url';

const Logo = () => {
  return (
    <div className="flex flex-shrink-0 items-center px-4">
      <img className="h-12 w-auto" src={logo} alt="VIA" />
      <span className="sr-only">Via Work</span>
    </div>
  );
};

type SomethingWrongProps = {
  errorCode?: string;
  description?: string;
  title?: string;
};

const handleLogout = async () => {
  await logout();
  windowReplaceOriginUrl();
};

export const SomethingWrong = ({
  errorCode,
  description = `We're sorry for the inconvenience. Please try refreshing the page or contacting support if the problem persists. Thank you for your patience.`,
  title = 'Oops, something went wrong!',
}: SomethingWrongProps) => {
  return (
    <div className="flex h-screen flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <Logo />
        </div>
        <div className="py-16">
          <div className="text-center">
            {errorCode && <p className="text-base font-semibold text-froly-600">{errorCode}</p>}
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-ebony-clay-900 sm:text-5xl">
              {title}
            </h1>
            <p className="mt-2 text-base text-ebony-clay-500">{description}</p>
            <div className="mt-6">
              <a href="/">
                <TextButton variant="danger">
                  Go back home <span aria-hidden="true"> &rarr;</span>
                </TextButton>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a href="mailto:international-support@justworks.com">
            <TextButton variant="secondary">Contact Support</TextButton>
          </a>
          <span className="inline-block border-l border-ebony-clay-300" aria-hidden="true" />
          <TextButton variant="danger" onClick={handleLogout}>
            Sign out
          </TextButton>
        </nav>
      </footer>
    </div>
  );
};
